import React, { useEffect, useState } from "react";
import styles from "./tableOfDetails.module.scss";
import Loading from "../../../../loading";
import t from "../../../../../_config/constants";
import { FcCheckmark } from "react-icons/fc";
import Button from "../../../../button";
import Checkbox from "../../../../checkbox";

const TableOfDetails = ({
  types,
  restaurant,
  loading,
  available,
  handleSubmut,
}) => {
  const [updating, setUpdating] = useState(false);
  const [usingContainers, setUsingContainers] = useState([]);
  useEffect(() => {
    setUsingContainers(
      restaurant.usingContainers ? restaurant.usingContainers : []
    );
  }, [restaurant]);
  const getAmount = (typeId) => {
    let sum = 0;
    let found = available.filter((item) => item.containerTypeId === typeId);
    if (found && found.length > 0) {
      found.map((item) => {
        sum += item.amount;
      });
    }
    sum = sum > 0 ? sum : 0;
    return sum;
  };
  const updateUsedContainers = (containerTypeId) => {
    let arr;
    if (usingContainers.includes(containerTypeId)) {
      arr = usingContainers.filter((items) => items !== containerTypeId);
    } else {
      arr = [...usingContainers, containerTypeId];
    }
    setUsingContainers(arr);
  };
  const cancelUpdate = () => {
    setUpdating(false);
    setUsingContainers(
      restaurant.usingContainers ? restaurant.usingContainers : []
    );
  };
  const handleUpdate = (e, usingContainers) => {
    setUpdating(false);
    handleSubmut(e, usingContainers);
  };
  return (
    <>
      <div className={styles.table}>
        <table>
          <thead>
            <tr className={styles.topBar}>
              <th colSpan="4">{t.restaurant.text_th_current_status}</th>
              {updating ? <th colSpan="1"></th> : <th colSpan="2"></th>}
              {updating && (
                <th colSpan="1">
                  <Button label={"Cancel"} onClick={cancelUpdate} />
                </th>
              )}
              <th colSpan="1" className={loading ? styles.btnDisabled : ""}>
                <Button
                  label={updating ? "Save" : "Edit"}
                  onClick={
                    updating
                      ? (e) => handleUpdate(e, usingContainers)
                      : () => setUpdating(true)
                  }
                  disabled={loading}
                />
              </th>
            </tr>
            <tr>
              <th>{t.restaurant.text_th_type}</th>
              <th>{t.restaurant.text_th_delivery}</th>
              <th>{t.restaurant.text_th_used_since}</th>
              <th>{t.restaurant.text_th_returned_since}</th>
              <th>{t.restaurant.text_th_available}</th>
              <th>{t.restaurant.text_par_level}</th>
              <th>{t.restaurant.text_th_used_by_store}</th>
            </tr>
          </thead>
          <tbody>
            {!loading &&
              types &&
              types.length > 0 &&
              types.map((item) => (
                <tr key={item._id}>
                  <td>{item.name}</td>
                  <td>
                    <span>
                      {restaurant.containerSettings &&
                      restaurant.containerSettings[item._id] &&
                      restaurant.containerSettings[item._id].lastDelivery
                        ? restaurant.containerSettings[item._id].lastDelivery
                        : 0}
                    </span>
                  </td>
                  <td>
                    <span>
                      {restaurant.containerSettings &&
                      restaurant.containerSettings[item._id] &&
                      restaurant.containerSettings[item._id].usedSince
                        ? restaurant.containerSettings[item._id].usedSince
                        : 0}
                    </span>
                  </td>
                  <td>
                    <span>
                      {restaurant.containerSettings &&
                      restaurant.containerSettings[item._id] &&
                      restaurant.containerSettings[item._id].returnedSince
                        ? restaurant.containerSettings[item._id].returnedSince
                        : 0}
                    </span>
                  </td>
                  <td>
                    <span>{getAmount(item._id)} </span>
                  </td>
                  <td>
                    <span>
                      {restaurant.containerSettings &&
                      restaurant.containerSettings[item._id] &&
                      restaurant.containerSettings[item._id].parLevel
                        ? restaurant.containerSettings[item._id].parLevel
                        : "N/A"}{" "}
                    </span>
                  </td>
                  <td>
                    {updating ? (
                      <span>
                        <Checkbox
                          customClass="checkbox"
                          onChange={() => {
                            updateUsedContainers(item._id + "");
                          }}
                          checked={usingContainers.includes(item._id + "")}
                        />
                      </span>
                    ) : (
                      <span>
                        {usingContainers.includes(item._id + "") && (
                          <div className={styles.visible}>
                            <FcCheckmark />
                          </div>
                        )}
                      </span>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        {loading && (
          <div className={styles.loading}>
            <Loading width={50} height={50} />
          </div>
        )}
      </div>
    </>
  );
};

export default TableOfDetails;
