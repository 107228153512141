import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { mainActions } from "../../../../_store/actions/main.actions";
import {
  getDropdownListMonths,
  isSameMonthAndYear,
  mapDailyTransactions,
  getDropdownListYears,
} from "../../../../_utils/mapTransactions";
import { isObjectEmpty } from "../../../../_utils/utils";
import RentedChart from "./rentedChart";
const Statistic = ({ restaurant }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [arrayForLineChart, setarrayForLineChart] = useState([]);
  const [monthsOptions, setMonthsOptions] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState();
  const [invoice, setInvoice] = useState([]);
  const [thisMonthTransactions, setThisMonthTransactions] = useState([]);
  const [rentedContainers, setRentedContainers] = useState(0);
  const [selectedContainerType, setSelectedContainerType] = useState("");
  const [yearsOptions, setYearsOptions] = useState([]);
  const [selectedYear, setSelectedYear] = useState();

  const getInvoice = async (month) => {
    const firstDay = new Date(
      Date.UTC(month.getFullYear(), month.getMonth() + 1, 1)
    ).getTime();
    const lastDay = new Date(
      Date.UTC(month.getFullYear(), month.getMonth() + 2, 0)
    ).getTime();
    let filter = [];
    filter.push({
      user_id: restaurant._id,
      transactionType: "RESTAURANT_INVOICE",
      createdAt: { $gte: firstDay, $lte: lastDay },
    });
    let response = await dispatch(
      mainActions.run("transactions", "transaction", "all", { filter })
    );
    setInvoice(response);
    setLoading(false);
  };
  const getTransactions = async () => {
    let date = new Date();
    let firstDay = new Date(date.getFullYear(), date.getMonth(), 1).getTime();
    let today = new Date().getTime();
    let filter = [];
    filter.push({
      restaurant_id: restaurant._id,
      createdAt: { $gte: firstDay, $lte: today },
    });
    let response = await dispatch(
      mainActions.run(
        "restaurantPendingTransaction",
        "restaurantTransaction",
        "all",
        { filter }
      )
    );
    setThisMonthTransactions(response);
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    if (restaurant._id) {
      getTransactions();
    }
  }, [restaurant]);
  useEffect(() => {
    const arr = getDropdownListYears();
    setSelectedYear(arr[0].value);
    setYearsOptions(arr);
  }, []);

  useEffect(() => {
    const arr = getDropdownListMonths(selectedYear);
    setSelectedMonth(arr[0].value);
    setMonthsOptions(arr);
  }, [selectedYear]);

  useEffect(() => {
    let chartData = [];

    if (
      isObjectEmpty(invoice) &&
      isSameMonthAndYear(selectedMonth, new Date().getTime())
    ) {
      chartData = mapDailyTransactions(
        thisMonthTransactions,
        selectedContainerType
      );
    } else if (!isObjectEmpty(invoice)) {
      chartData = mapDailyTransactions(
        invoice[0] ? invoice[0].transactionList : invoice.transactionList,
        selectedContainerType,
        selectedMonth
      );
    } else {
      chartData = mapDailyTransactions(
        [],
        selectedContainerType,
        selectedMonth
      );
    }
    setarrayForLineChart(chartData);

    let total = chartData?.reduce(
      (prevValue, value) => Number(prevValue) + Number(value.value),
      [0]
    );
    setRentedContainers(total);
  }, [invoice, selectedContainerType]);
  useEffect(() => {
    if (
      selectedMonth &&
      !isSameMonthAndYear(selectedMonth, new Date().getTime())
    ) {
      setLoading(true);
      getInvoice(selectedMonth);
    } else {
      setInvoice({});
    }
  }, [selectedMonth, thisMonthTransactions]);
  return (
    <RentedChart
      loading={loading}
      rentedContainers={rentedContainers}
      selectedMonth={selectedMonth}
      setSelectedContainerType={setSelectedContainerType}
      monthsOptions={monthsOptions}
      arrayForLineChart={arrayForLineChart}
      setSelectedMonth={setSelectedMonth}
      regionId={restaurant.regionId}
      yearsOptions={yearsOptions}
      selectedYear={selectedYear}
      setSelectedYear={setSelectedYear}
    />
  );
};
export default Statistic;
