import React, { useEffect, useState } from "react";
import styles from "./rented.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { makeSelectMap } from "../../../../_utils/utils";
import {
  getDropdownListMonths,
  getDropdownListYears,
  isSameMonthAndYear,
  mapDailyTransactions,
  mapMonthlyTransactions,
} from "../../../../_utils/mapTransactions";
import { mainActions } from "../../../../_store/actions/main.actions";
import RentedChart from "../../../restaurant/monthlySummary/statistic/rentedChart";

const UsageRentedChart = () => {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();

  const [restaurants, setRestaurants] = useState([]);
  const [loading, setLoading] = useState(true);
  const [invoices, setInvoices] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState();
  const [monthsOptions, setMonthsOptions] = useState([]);
  const [restaurantOptions, setRestaurantOptions] = useState([]);
  const [arrayForLineChart, setarrayForLineChart] = useState([]);
  const [thisMonthTransactions, setThisMonthTransactions] = useState([]);
  const [rentedContainers, setRentedContainers] = useState(0);
  const [selectedContainerType, setSelectedContainerType] = useState("");
  const [yearsOptions, setYearsOptions] = useState([]);
  const [selectedYear, setSelectedYear] = useState();
  const [subregionId, setSubregionId] = useState("");
  const [selectedRestaurant, setSelectedRestaurant] = useState("");
  const [selectedMonthly, setSelectedMonthly] = useState(false);
  const [yearlyInvoices, setYearlyInvoices] = useState([]);

  useEffect(() => {
    const arr = getDropdownListYears();
    setSelectedYear(arr[0].value);
    setYearsOptions(arr);
  }, []);
  useEffect(() => {
    setLoading(true);
    if (restaurants) {
      const getTransactions = async () => {
        let date = new Date();
        let firstDay = new Date(
          date.getFullYear(),
          date.getMonth(),
          1
        ).getTime();
        let today = new Date().getTime();
        let filter = [];
        filter.push({
          restaurant_id: selectedRestaurant
            ? selectedRestaurant
            : { $in: restaurants.map((item) => item._id) },
          createdAt: { $gte: firstDay, $lte: today },
        });
        let response = await dispatch(
          mainActions.run(
            "restaurantPendingTransaction",
            "restaurantTransaction",
            "all",
            { filter }
          )
        );
        setThisMonthTransactions(response);
        setLoading(false);
      };
      getTransactions();
    }
  }, [restaurants, selectedRestaurant]);
  useEffect(() => {
    const subregionId = store?.auth?.user?.subregionId;
    setSubregionId(subregionId);
    const getRestaurants = async (id) => {
      let filter = [];
      if (id) filter.push({ subregionId: id });
      let data = await dispatch(
        mainActions.run("profiles", "restaurant", "all", { filter })
      );
      if (data) {
        setRestaurants(data);
        let arr = makeSelectMap(data);
        let filterOptions = [...arr];
        filterOptions.unshift({ label: "All Stores", value: "" });
        setRestaurantOptions(filterOptions);
      }
    };
    if (store.auth && store.auth.user && subregionId) {
      getRestaurants(subregionId);
    }
    setSelectedRestaurant("");
  }, [store.auth && store.auth.user]);

  useEffect(() => {
    let chartData = [];
    if (!selectedMonthly) {
      if (
        !invoices.length &&
        isSameMonthAndYear(selectedMonth, new Date().getTime())
      ) {
        chartData = mapDailyTransactions(
          thisMonthTransactions,
          selectedContainerType
        );
      } else if (invoices.length) {
        let transactions = [];
        invoices.map(
          (invoice) =>
            (transactions = [...transactions, ...invoice.transactionList])
        );
        chartData = mapDailyTransactions(
          transactions,
          selectedContainerType,
          selectedMonth
        );
      } else {
        chartData = mapDailyTransactions(
          [],
          selectedContainerType,
          selectedMonth
        );
      }
    } else {
      chartData = mapMonthlyTransactions(
        yearlyInvoices,
        thisMonthTransactions,
        selectedYear,
        selectedContainerType
      );
    }
    setarrayForLineChart(chartData);
    let total = chartData?.reduce(
      (prevValue, value) => Number(prevValue) + Number(value.value),
      [0]
    );
    setRentedContainers(total);
  }, [selectedContainerType, invoices, yearlyInvoices, selectedMonthly]);
  useEffect(() => {
    setLoading(true);
    if (restaurants) {
      const getInvoices = async () => {
        const firstDay = new Date(Date.UTC(selectedYear, 1)).getTime();
        const lastDay = new Date(Number(selectedYear) + 1, 0, 2).getTime();
        let filter = [];
        filter.push({
          user_id: selectedRestaurant
            ? selectedRestaurant
            : { $in: restaurants.map((item) => item._id) },
          transactionType: "RESTAURANT_INVOICE",
          createdAt: { $gte: firstDay, $lte: lastDay },
        });
        let response = await dispatch(
          mainActions.run("transactions", "transaction", "all", { filter })
        );
        setYearlyInvoices(response);
        setLoading(false);
      };
      getInvoices();
    }
  }, [restaurants, selectedYear, selectedRestaurant]);
  useEffect(() => {
    if (
      !selectedMonthly &&
      selectedMonth &&
      !isSameMonthAndYear(selectedMonth, new Date().getTime())
    ) {
      const getInvoices = async (month) => {
        const firstDay = new Date(
          Date.UTC(month.getFullYear(), month.getMonth() + 1, 1)
        ).getTime();
        const lastDay = new Date(
          Date.UTC(month.getFullYear(), month.getMonth() + 2, 0)
        ).getTime();
        let filter = [];
        filter.push({
          user_id: selectedRestaurant
            ? selectedRestaurant
            : { $in: restaurants?.map((item) => item._id) },
          transactionType: "RESTAURANT_INVOICE",
          createdAt: { $gte: firstDay, $lte: lastDay },
        });
        let response = await dispatch(
          mainActions.run("transactions", "transaction", "all", { filter })
        );
        if (response) setInvoices(response);
        setLoading(false);
      };
      setLoading(true);
      getInvoices(selectedMonth);
    } else {
      setInvoices([]);
    }
  }, [selectedMonth, thisMonthTransactions, selectedRestaurant]);
  useEffect(() => {
    if (selectedYear) {
      const arr = getDropdownListMonths(selectedYear);
      setSelectedMonth(arr[0].value);
      setMonthsOptions(arr);
    }
  }, [selectedYear]);
  return (
    <div className={styles.wrapper}>
      <div className={styles.graphContainer}>
        <RentedChart
          loading={loading}
          rentedContainers={rentedContainers}
          selectedMonth={selectedMonth}
          setSelectedContainerType={setSelectedContainerType}
          monthsOptions={monthsOptions}
          arrayForLineChart={arrayForLineChart}
          setSelectedMonth={setSelectedMonth}
          regionId={""}
          subregionId={subregionId}
          onDashboard={true}
          restaurantOptions={restaurantOptions}
          setSelectedRestaurant={setSelectedRestaurant}
          selectedMonthly={selectedMonthly}
          setSelectedMonthly={setSelectedMonthly}
          selectedRestaurant={selectedRestaurant}
          selectedYear={selectedYear}
          setSelectedYear={setSelectedYear}
          yearsOptions={yearsOptions}
        />
      </div>
      <div className={styles.graphContainer}></div>
    </div>
  );
};

export default UsageRentedChart;
