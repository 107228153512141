import React, { useEffect, useState } from "react";
import Button from "../../../button";
import Input from "../../../input";
import Selector from "../../../selector";
import styles from "./containers.module.scss";
import queryString from "query-string";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";
import { mainActions } from "../../../../_store/actions/main.actions";
import toastService from "../../../../_services/toast.service";
import TableOfDetails from "./tableOfDetails";
import { makeSelectMap } from "../../../../_utils/utils";
import { isObjectEmpty } from "../../../../_utils/utils";
import { updateAvailable } from "../../../../_services/container.service";
import t from "../../../../_config/constants";
import Checkbox from "../../../checkbox";
const Containers = ({
  onContainersClick,
  onPricingClick,
  onLogsClick,
  activeTab,
}) => {
  const history = useHistory();
  const { id } = queryString.parse(history.location.search);
  const dispatch = useDispatch();
  const [restaurant, setRestaurant] = useState({});
  const [types, setTypes] = useState([]);
  const [error, setError] = useState(false);
  const [availableError, setAvailableError] = useState(false);
  const [optionsArray, setOptionsArray] = useState([]);
  const [update, setUpdate] = useState(1);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    type: "",
    amount: "",
    name: "",
  });

  const [availableEdit, setAvailableEdit] = useState({
    containerTypeId: "",
    name: "",
    newAvailable: "",
  });
  const [noParLevel, setNoParLevel] = useState(false);
  const [availableContainers, setAvailableContainers] = useState([]);
  useEffect(() => {
    if (noParLevel) {
      setData((prevState) => ({ ...prevState, amount: "" }));
    }
  }, [noParLevel]);
  const getAllAvailableContainers = async (id) => {
    let response = await dispatch(
      mainActions.run("availableContainers", "availableRestaurant", "all", {
        filter: [{ restaurant_id: id }],
      })
    );
    setAvailableContainers(response);
  };

  useEffect(() => {
    if (!isObjectEmpty(restaurant)) getAllAvailableContainers(restaurant._id);
  }, [restaurant]);

  const fetchAllContainerTypes = async (regionId) => {
    let filter = [];
    if (regionId && regionId !== "*") {
      filter.push({ regionId: regionId });
    }
    let data = await dispatch(
      mainActions.run("data", "containerType", "all", { filter })
    );
    let arr = makeSelectMap(data);
    setOptionsArray(arr);
    setTypes(data);
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    if (restaurant.regionId) {
      fetchAllContainerTypes(restaurant.regionId);
    }
  }, [restaurant]);

  const getRestaurant = async (id) => {
    let data = await dispatch(
      mainActions.run("profiles", "restaurant", "id", id)
    );
    setRestaurant(data);
  };

  useEffect(() => {
    getRestaurant(id);
  }, [update]);

  const handleSelector = (e) => {
    let value = e.value;
    let label = e.label;
    let typeValue = {
      type: value,
      name: label,
      amount: "",
    };
    setData(typeValue);
    setError(false);
  };

  const handleSelectorAvailable = (e) => {
    let value = e.value;
    let label = e.label;
    let typeValue = {
      containerTypeId: value,
      name: label,
      newAvailable: "",
    };
    setAvailableEdit(typeValue);
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setError(false);
    setData((data) => ({ ...data, [name]: value }));
  };

  const handleChangeAvailable = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setAvailableError(false);
    setAvailableEdit((data) => ({ ...data, [name]: value }));
  };

  const updateContainer = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      if (restaurant._id) {
        setLoading(true);
        let obj = { ...restaurant };
        if (obj.containerSettings) {
          obj.containerSettings[data.type] = {
            ...obj.containerSettings[data.type],
            parLevel: data.amount,
            name: data.name,
          };
          setData(obj);
        } else {
          obj.containerSettings = {};
          obj.containerSettings[data.type] = {
            ...obj.containerSettings[data.type],
            parLevel: data.amount,
            name: data.name,
          };
          setData(obj);
        }

        let response = await dispatch(
          mainActions.run("profiles", "restaurant", "update", obj)
        );
        if (response) {
          toastService.show(
            "success",
            t.restaurant.text_successfully_edited_par_level
          );
          setData({
            type: data.type,
            amount: "",
          });
          setUpdate(update === 1 ? 2 : 1);
        } else {
          setLoading(false);
        }
      }
    } else {
      toastService.show("error", `${t.common.text_toast_all_fields_required}`);
      setError(true);
    }
  };

  const validateForm = () => {
    return (
      (noParLevel ||
        (data.amount &&
          data.amount > 0 &&
          data.amount == Math.round(data.amount))) &&
      data.type
    );
  };

  const validateAvailable = () => {
    return (
      availableEdit.newAvailable &&
      availableEdit.newAvailable >= 0 &&
      availableEdit.containerTypeId &&
      availableEdit.newAvailable == Math.round(availableEdit.newAvailable)
    );
  };

  const handleAvailableUpdate = async (e) => {
    e.preventDefault();
    if (validateAvailable()) {
      setLoading(true);
      let data = { ...availableEdit };
      data.restaurant_id = restaurant._id;

      const availableCopy = [...availableContainers];
      let currentAvailable = 0;
      let found = availableCopy.filter(
        (item) => item.containerTypeId === data.containerTypeId
      );

      if (found && found.length > 0) {
        found.map((item) => {
          currentAvailable += item.amount;
        });
      }
      data.currentAvailable = currentAvailable;
      let response = await updateAvailable(data);
      if (response) {
        toastService.show(
          "success",
          `${t.restaurant.text_successfully_edited_number} ${data.name}!`
        );
        setUpdate(update === 1 ? 2 : 1);
        setAvailableEdit({
          containerTypeId: "",
          name: "",
          newAvailable: "",
        });
      } else {
        setLoading(false);
      }
    } else {
      setAvailableError(true);
    }
  };
  const handleCheckbox = () => {
    setNoParLevel((prevState) => !prevState);
    setError(false);
  };
  const handleUsingContainersUpdate = async (e, usingContainers) => {
    e.preventDefault();
    if (restaurant._id) {
      setLoading(true);
      let response = await dispatch(
        mainActions.run("profiles", "restaurant", "update", {
          _id: restaurant._id,
          usingContainers,
        })
      );
      if (response) {
        toastService.show(
          "success",
          t.restaurant.text_successfully_edited_used_containers
        );
        setUpdate(update === 1 ? 2 : 1);
      } else {
        setLoading(false);
      }
    }
  };
  useEffect(() => {}, [availableEdit]);
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <p
          className={
            activeTab === "containers" ? styles.tabActive : styles.tabInactive
          }
          onClick={onContainersClick}
        >
          {" "}
          {t.restaurant.text_containers_settings}
        </p>
        <p
          className={
            activeTab === "pricing" ? styles.tabActive : styles.tabInactive
          }
          onClick={onPricingClick}
        >
          {" "}
          {t.restaurant.text_pricing_management}{" "}
        </p>
        <p
          className={
            activeTab === "logs" ? styles.tabActive : styles.tabInactive
          }
          onClick={onLogsClick}
        >
          {" "}
          {t.restaurant.text_th_logs_of_activity}{" "}
        </p>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.tables}>
          <TableOfDetails
            handleSubmut={handleUsingContainersUpdate}
            restaurant={restaurant}
            types={types}
            loading={loading}
            available={availableContainers}
          />
        </div>
        <div className={styles.forms}>
          <div className={styles.parLevel}>
            <h6>{t.restaurant.text_par_level}</h6>
            <form>
              <div className={styles.select}>
                <Selector
                  selectClassname="selectSecondaryWhiteBg"
                  options={optionsArray}
                  placeholder={t.restaurant.text_select_type}
                  name="containerType"
                  value={optionsArray.name}
                  handleChangeSelect={handleSelector}
                />
              </div>
              <Checkbox
                customClass="checkbox"
                label={t.restaurant.text_par_level_na}
                checked={noParLevel}
                onChange={handleCheckbox}
              />
              <p>{t.restaurant.text_amount}</p>
              <div className={styles.input}>
                <Input
                  label=""
                  name="amount"
                  inputClass="inputBorder"
                  type="number"
                  value={data.amount}
                  placeholder={t.restaurant.text_amount}
                  onChange={handleChange}
                  disabled={noParLevel}
                />
              </div>
              <div className={styles.button}>
                <Button
                  label={t.button.text_update}
                  btnClass="btnNormalCasal"
                  onClick={updateContainer}
                />
              </div>
            </form>
            {error ? (
              <div className={styles.error}>
                <p>{t.common.text_all_fields_required}</p>
              </div>
            ) : null}
          </div>

          <div className={styles.storageCapacity}>
            <h6>{t.restaurant.text_available_containers}</h6>
            <form>
              <div className={styles.select}>
                <Selector
                  selectClassname="selectSecondaryWhiteBg"
                  options={optionsArray}
                  placeholder={t.restaurant.text_select_type}
                  name="available"
                  value={optionsArray.name}
                  handleChangeSelect={handleSelectorAvailable}
                />
              </div>
              <p>{t.restaurant.text_amount}</p>
              <div className={styles.input}>
                <Input
                  label=""
                  name="newAvailable"
                  inputClass="inputBorder"
                  type="number"
                  value={availableEdit.newAvailable}
                  placeholder={t.restaurant.text_amount}
                  onChange={handleChangeAvailable}
                />
              </div>
              <div className={styles.button}>
                <Button
                  label={t.button.text_update}
                  btnClass="btnNormalCasal"
                  onClick={handleAvailableUpdate}
                />
              </div>
            </form>
            {availableError ? (
              <div className={styles.error}>
                <p>{t.common.text_all_fields_required}</p>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Containers;
