import React, { useEffect, useState } from "react";
import styles from "./list.module.scss";
import {
  compareUniqueContainerIds,
  isObjectEmpty,
} from "../../../../_utils/utils";
import Checkbox from "../../../checkbox";
import ClipLoader from "react-spinners/ClipLoader";
import SearchBar from "../../../searchBar";
import { useDispatch } from "react-redux";
import useDebounce from "../../../../_utils/debounce";
import { mainActions } from "../../../../_store/actions/main.actions";
import ConfirmModal from "../../../modal/confirmModal";
import toastService from "../../../../_services/toast.service";
import { termRegex } from "../../../../_utils/utils";
import t from "../../../../_config/constants";
import ContainerDetails from "./details";
import { modalActions } from "../../../../_store/actions/modal.actions";
import RestaurantSelect from "../../../userManagement/single/containerDetails/borrowed/restaurantSelect";
import FormModal from "../../../modal/formModal";
const ContainerList = ({ selectedBatch, onUpdate }) => {
  const [containersLoader, setContainersLoader] = useState(false);
  const [showDeleteContainers, setShowDeleteContainers] = useState(false);
  const [selectedContainers, setSelectedContainers] = useState([]);
  const [selectedContainersObjects, setSelectedContainersObjects] = useState(
    []
  );

  const [searchTerm, setSearchTerm] = useState("");
  const dispatch = useDispatch();
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const [showModal, setShowModal] = useState(false);

  const openDeleteContainer = () => {
    setShowDeleteContainers(true);
  };

  const [containers, setContainers] = useState([]);
  const onChangeTermContainer = (e) => {
    e.preventDefault();
    setSearchTerm(e.target.value);
  };

  const handleCheckedContainers = (id) => {
    if (selectedContainers.includes(id)) {
      let filtered = selectedContainers.filter((item) => item !== id);
      setSelectedContainers(filtered);
    } else {
      setSelectedContainers((prevState) => [...prevState, id]);
    }
  };

  const handleCheckedContainersObjects = (container) => {
    if (selectedContainersObjects.includes(container)) {
      let filtered = selectedContainersObjects.filter(
        (item) => item !== container
      );
      setSelectedContainersObjects(filtered);
    } else {
      setSelectedContainersObjects((prevState) => [...prevState, container]);
    }
  };
  const fetchContainers = async (id) => {
    setContainersLoader(true);
    let filter = [{ batchId: id }];
    if (searchTerm) {
      filter.push({
        $or: [
          {
            uniqueContainerId: {
              $regex: `${termRegex(searchTerm)}`,
              $options: "i",
            },
          },
        ],
      });
    }
    let response = await dispatch(
      mainActions.run("data", "container", "all", { filter })
    );
    const sortedData = response.sort(compareUniqueContainerIds);
    setContainers(sortedData);
    setContainersLoader(false);
  };

  useEffect(() => {
    if (!isObjectEmpty(selectedBatch)) {
      fetchContainers(selectedBatch._id);
    } else {
      setContainers([]);
    }
    setSelectedContainers([]);
    setSelectedContainersObjects([]);
  }, [selectedBatch, debouncedSearchTerm]);

  const deleteContainers = async (answer, data) => {
    if (answer) {
      let response = await dispatch(
        mainActions.run(
          "data",
          "container",
          "deleteMulti",
          { ids: data, type: "container", batchId: selectedBatch._id },
          {
            message:
              t.containerManagement.add.text_successfully_deleted_containers,
          }
        )
      );
      if (response.success) {
        setShowDeleteContainers(false);
        onUpdate();
        setSelectedContainers([]);
        setSelectedContainersObjects([]);
      } else {
        toastService.show("warning", t.common.text_something_went_wrong);
      }
    } else {
      setShowDeleteContainers(false);
    }
  };
  const closeModalNoAction = () => {
    setShowModal(false);
  };
  const openModal = () => {
    const found = selectedContainersObjects.find(
      (c) => c.state !== "user" || !c.user_id
    );
    if (found) {
      toastService.show(
        "error",
        "Container can't be returned. Container is not assigned to a user."
      );
      return;
    }

    setShowModal(true);
  };
  return (
    <div className={containersLoader ? styles.loadingWrapper : styles.wrapper}>
      {showDeleteContainers && (
        <ConfirmModal
          show={showDeleteContainers}
          handleYesNoAnswer={deleteContainers}
          modalData={selectedContainers}
          question={t.containerManagement.add.text_modal_delete_containers}
        />
      )}
      {showModal && (
        <FormModal
          show={showModal}
          handleClose={closeModalNoAction}
          content={
            <RestaurantSelect
              containers={selectedContainersObjects}
              handleClose={closeModalNoAction}
            />
          }
          size="md"
        />
      )}
      {!isObjectEmpty(selectedBatch) && (
        <div className={styles.header}>
          <h3>{t.containerManagement.add.text_container_list}</h3>
          <p
            className={styles.retireButton}
            style={
              selectedContainers.length > 0
                ? { cursor: "pointer" }
                : { cursor: "not-allowed" }
            }
            onClick={selectedContainers.length > 0 ? openDeleteContainer : null}
          >
            {t.button.text_retire}
          </p>
          <p
            className={styles.retire}
            style={
              selectedContainers.length == 1
                ? { cursor: "pointer" }
                : { cursor: "not-allowed" }
            }
            onClick={selectedContainers.length == 1 ? openModal : null}
            title={
              selectedContainers.length == 0
                ? "Select a container"
                : selectedContainers.length == 1
                ? "Return container"
                : "Select just one container"
            }
          >
            {t.button.text_return}
          </p>
        </div>
      )}
      {!isObjectEmpty(selectedBatch) && (
        <div className={styles.searchbar}>
          <SearchBar
            customClass="search"
            placeholder={
              t.containerManagement.add.text_search_container_placeholder
            }
            name="searchTerm"
            value={searchTerm}
            onChange={onChangeTermContainer}
          />
        </div>
      )}
      {!isObjectEmpty(selectedBatch) && (
        <div className={styles.section}>
          <p>{t.containerManagement.add.text_th_containerId}</p>
        </div>
      )}
      {isObjectEmpty(selectedBatch) && (
        <div className={styles.noData}>
          <span>{t.containerManagement.add.text_select_guideline}</span>
        </div>
      )}
      {containersLoader && (
        <div className={styles.loading}>
          <div className={styles.loaderHeader}>
            <h3>{t.common.text_reusables}</h3>
          </div>
          <ClipLoader color="#73aabd" size={100} />
        </div>
      )}
      {!isObjectEmpty(selectedBatch) && (
        <div className={styles.list}>
          {containers &&
            containers.map((item, index) => (
              <div className={styles.item} key={index}>
                <p
                  onClick={() =>
                    dispatch(
                      modalActions.openModal(
                        <ContainerDetails containerId={item._id} />,
                        "",
                        "xlg"
                      )
                    )
                  }
                >
                  {index + 1}. {item.uniqueContainerId}
                </p>
                <div className={styles.checkbox}>
                  <Checkbox
                    customClass="checkbox"
                    checked={selectedContainers.includes(item._id)}
                    onChange={() => {
                      handleCheckedContainers(item._id);
                      handleCheckedContainersObjects(item);
                    }}
                    label=""
                  />
                </div>
              </div>
            ))}
          {!containersLoader && !containers.length && (
            <div>
              <p>{t.containerManagement.add.text_no_containers}</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ContainerList;
