export const isSameMonthAndYear = (date1, date2) => {
  const firstDate = new Date(date1);
  const secondDate = new Date(date2);
  return (
    firstDate.getMonth() === secondDate.getMonth() &&
    firstDate.getFullYear() === secondDate.getFullYear()
  );
};

export const mapDailyTransactions = (
  transactions,
  containerType,
  selectedMonth
) => {
  let data = [];
  let today;
  let month;
  let year;
  if (selectedMonth) {
    today = new Date(
      new Date(selectedMonth).getFullYear(),
      new Date(selectedMonth).getMonth() + 1,
      0
    ).getDate();
    month = new Date(selectedMonth).getMonth();
    year = new Date(selectedMonth).getFullYear();
  } else {
    today = new Date().getDate();
    month = new Date().getMonth();
    year = new Date().getFullYear();
  }

  for (let i = 0; i < today; i++) {
    data.push({
      day: i + 1,
      label: new Date(year, month, i + 1).getDay(),
      quantity: 0,
    });
  }
  if (transactions && transactions.length > 0) {
    transactions.map((transaction) => {
      if (
        (containerType && transaction.containerTypeId === containerType) ||
        !containerType
      ) {
        let day = new Date(transaction.createdAt).getDate();
        let monthOfRental = new Date(transaction.createdAt).getMonth();
        let index = data.findIndex((item) => item.day === day);
        if (monthOfRental !== month) {
          data[0].quantity += transaction.quantity;
        } else if (index !== -1) {
          data[index].quantity += transaction.quantity;
        } else {
          data.push({
            day: new Date(transaction.createdAt).getDate(),
            label: new Date(transaction.createdAt).getDay(),
            quantity: transaction.quantity,
          });
        }
      }
    });

    data.sort(function (a, b) {
      return a.day - b.day;
    });
  }

  let chartData = data.map((item) => ({
    labels: item.day,
    value: Number(item.quantity),
  }));
  return chartData;
};

export const mapMonthlyTransactions = (
  invoices,
  thisMonthTransactions,
  selectedYear,
  containerType
) => {
  let months = [
    "Jan",
    "Feb",
    "March",
    "April",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];
  let data = [];
  let month = 11;
  let thisYear = false;
  if (selectedYear == new Date().getFullYear()) {
    month = new Date().getMonth();
    thisYear = true;
  }
  for (let i = 0; i <= month; i++) {
    data.push({
      month: i,
      quantity: 0,
    });
  }
  if (invoices.length) {
    invoices.map((invoice) => {
      let transactions = invoice.transactionList;
      transactions.map((transaction) => {
        if (
          (containerType && transaction.containerTypeId === containerType) ||
          !containerType
        ) {
          let month = new Date(transaction.createdAt).getMonth();
          let index = data.findIndex((item) => item.month === month);
          if (index !== -1) {
            data[index].quantity += transaction.quantity;
          } else {
            data.push({
              month: new Date(transaction.createdAt).getMonth(),
              quantity: transaction.quantity,
            });
          }
        }
      });
    });
  }
  if (thisYear && thisMonthTransactions.length) {
    thisMonthTransactions.map((transaction) => {
      if (
        (containerType && transaction.containerTypeId === containerType) ||
        !containerType
      ) {
        let month = new Date(transaction.createdAt).getMonth();
        let index = data.findIndex((item) => item.month === month);
        if (index !== -1) {
          data[index].quantity += transaction.quantity;
        } else {
          data.push({
            month: new Date(transaction.createdAt).getMonth(),
            quantity: transaction.quantity,
          });
        }
      }
    });
  }
  let chartData = data.map((item) => ({
    labels: months[item.month],
    value: Number(item.quantity),
  }));
  return chartData;
};
export const getDropdownListMonths = (selectedYear, registration) => {
  let date = new Date();
  let arr = [];
  let months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  if (selectedYear != date.getFullYear()) {
    for (let i = 11; i >= 0; i--) {
      arr.push({
        label: months[i],
        value: registration ? i : new Date(selectedYear, i),
      });
    }
  } else if (selectedYear != 2021) {
    for (let i = date.getMonth(); i >= 0; i--) {
      arr.push({
        label: months[i],
        value: registration ? i : new Date(selectedYear, i),
      });
    }
  } else if (selectedYear == 2021) {
    for (let i = date.getMonth(); i >= 6; i--) {
      arr.push({
        label: months[i],
        value: registration ? i : new Date(selectedYear, i),
      });
    }
  }
  return arr;
};
export const getDropdownListYears = (downloadInvoices = false) => {
  let date = new Date();
  let arr = [];
  if (downloadInvoices && date.getMonth() === 0) {
    for (let i = date.getFullYear() - 1; i >= 2021; i--) {
      arr.push({
        label: i,
        value: i,
      });
    }
  } else {
    for (let i = date.getFullYear(); i >= 2021; i--) {
      arr.push({
        label: i,
        value: i,
      });
    }
  }
  return arr;
};
export const sortTransactions = (transactions) => {
  let list = [];
  let subtotal = 0;
  transactions.forEach((transaction) => {
    subtotal += Number(transaction.price * transaction.quantity);
    let index = list.findIndex(
      (item) =>
        item.containerTypeId === transaction.containerTypeId &&
        item.price === transaction.price &&
        item.name === transaction.name
    );
    if (index !== -1) {
      list[index].quantity += transaction.quantity;
    } else {
      list.push({ ...transaction });
    }
  });
  list.sort(function (a, b) {
    return a.name - b.name;
  });
  return { list, subtotal };
};
export const sortUserTransactions = (transactions) => {
  let list = [];
  transactions.forEach((transaction) => {
    let index = list.findIndex(
      (item) =>
        item.containerTypeId === transaction.containerTypeId &&
        item.price === transaction.price &&
        item.name === transaction.name
    );
    if (index !== -1) {
      list[index].quantity += transaction.quantity;
    } else {
      list.push({ ...transaction });
    }
  });
  list.sort(function (a, b) {
    return a.name - b.name;
  });
  return list;
};
